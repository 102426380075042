<template lang="pug">
.login
  form.form.form-horizontal(@submit.prevent='submit')
    b-loading(:is-full-page='true' :active.sync='isLoading')
    div.mb-4(v-if='newPasswordMode')
      h2 Por favor elija una nueva contraseña para su cuenta
    .field(v-if='!newPasswordMode')
      label.label(for='email') Correo
      .control
        input#email.input(type='text' placeholder='Correo o Nombre de usuario' v-model='user.email' autofocus required)
    .field
      label.label(for='password') {{ newPasswordMode ? 'Nueva contraseña' : 'Contraseña' }}
      .control
        input#password.input(type='password' placeholder='Clave' v-model='user.password' autofocus :required='newPasswordMode')
    .form-button-container
        button.button.is-primary(type='submit') {{ newPasswordMode ? 'Crear contraseña' : 'Iniciar sesión' }}
</template>

<script>
import { userPermissions } from '@/constants/enums'
import { mapActions } from 'vuex'
import { map, inPoint } from '@/router/routes'
import toastService from '@/services/toast.service'
import authService from '@/services/auth.service'

export default {
  data () {
    return {
      isLoading: false,
      newPasswordMode: false,
      user: {
        id: 0,
        email: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),
    async submit () {
      this.isLoading = true
      if (this.newPasswordMode) {
        const result = await authService.assignPassword(this.user)
        if (result) {
          toastService.show('Clave asignada, por favor inicie sesión')
          this.newPasswordMode = false
          this.user.id = 0
        }
      } else {
        const result = await this.login(this.user)
        if (result) {
          if (result.needPassword) {
            this.newPasswordMode = true
            this.user.id = result.id
          } else if (result.permissionsArray.includes(userPermissions.INPOINT_APP)) {
            this.$router.push({ name: inPoint.name })
          } else {
            this.$router.push({ name: map.name })
          }
        }
      }
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables/sizes';

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  max-width: 450px;
  margin: 20px auto;
  padding: 20px;
  border-radius: $border-radius;
  box-shadow: 1px 1px 50px #000;
}
.form {
  // max-width: 350px;
  width: 100%;
}
</style>
